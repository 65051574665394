(function ($, undefined) {
  /*============================================
  Init swipers
  ==============================================*/
  $(function () {
    const $body = $('body'),
      testimonialsSwiper = new Swiper(".testimonials-swiper", {
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 3000,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".testimonials-swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 25,
          }
        }
      });

    if ($body.hasClass('home') && window.innerWidth >= 1400) {
      testimonialsSwiper.disable();
    }

    // Partners parallax slider
    $(window).on('load', function (e) {
      new Swiper(".partners-slider", {
        direction: "vertical",
        speed: 500,
        preloadImages: true,
        updateOnImagesReady: true,
        slidesPerView: 1,
        calculateHeight: true,
        autoHeight: true,
        mousewheel: true,
        pagination: {
          el: ".partners-slider .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          0: {
            direction: 'horizontal',
            spaceBetween: 50
          },
          992: {
            direction: 'vertical',
            spaceBetween: 150
          }
        }
      });
    });
  });

  /*============================================
   Scroll for Careers page
   ==============================================*/
  var $careersText = $('.js-careers-text');
  $(function () {
    $('a.js-scroll').click(function () {
      $('html, body').scrollTop($careersText.offset().top)
      return false
    })
  });

  /*============================================
  Features parallax effect
  todo: Run only on services page
  ==============================================*/
  $(function () {
    $.fn.isInViewport = function () {
      const elementTop = $(this).offset().top;
      const elementBottom = elementTop + $(this).outerHeight();
      const viewportTop = $(window).scrollTop();
      const viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    const $featuresSection = $('.features'),
      $featuresImgs = $featuresSection.find('.features-image-list img[data-id]'),
      FEATURE_MARGIN_TOP = 200,
      FEATURE_FIRST_ID = 1;

    // If not exist section element do not execute code below
    // todo: Remove then will configure run only services page
    if (!$featuresSection.length) return false;

    const showFeatureImgById = (id) => {
      $featuresImgs.removeClass("shown");
      $featuresSection.find(`.features-image-list img[data-id="${id}"]`).addClass("shown");
    }

    $(window).scroll(() => {
      if (!$featuresSection.isInViewport()) return false;

      const scrollTop = $(window).scrollTop();

      // Ignore on mobile devices
      if (window.innerWidth < 992) return false;

      for (let i = 1; i <= $featuresImgs.length; i++) {
        const currentElement = $(`.feature-item[data-id="${i}"]`),
          offsetTop = currentElement.offset().top - FEATURE_MARGIN_TOP,
          existsNextElementOffset = currentElement.next().offset();

        if (!existsNextElementOffset) return false;

        let nextElementOffsetTop = currentElement.next().offset().top - FEATURE_MARGIN_TOP;
        const isVisibleCurrentElementAndInvisibleNextElement = scrollTop > offsetTop && scrollTop < nextElementOffsetTop,
          isNextElementOffset = scrollTop > nextElementOffsetTop;

        if (isVisibleCurrentElementAndInvisibleNextElement) {
          showFeatureImgById(i);
          break;
        } else if (isNextElementOffset) {
          showFeatureImgById(i + 1);
        } else {
          showFeatureImgById(FEATURE_FIRST_ID);
        }
      }
    });
  });

  /*============================================
  Knowledge center: show category tab by hash
  ==============================================*/
  $(function () {
    if (!$('body.page-template-page-knowledge-center').length) {
      // exit
      return false;
    }

    const hashChange = () => {
      const hash = location.hash.replace(/^#/, '');
      if (hash) {
        const tabItem = $(`#pills-tab .nav-link[id="${hash}"]`);
        let tabItemOffsetTop;

        if (tabItem.offset()) {
          tabItemOffsetTop = tabItem.offset().top;
          tabItem.tab("show");
          document.documentElement.scrollTop = tabItemOffsetTop - 130;
        }
      }

      $('#pills-tab .nav-link').on('shown.bs.tab', (e) => {
        let tabId = "#" + e.target.id;
        tabId = tabId === '#all-tab' ? ' ' : tabId;
        history.pushState({}, null, tabId);
      });
    }

    hashChange();

    $(window).on('hashchange', hashChange);
  });

  /*============================================
  Knowledge single page - Table of contents
  ==============================================*/
  $(function () {
    if (!$('body.single-knowledge-center').length) {
      // exit
      return;
    }

    const $titles = $('.regular-text h2, .regular-text h3'),
      $items = $('.js-table-of-contents');

    if (!$titles.length || !$items.length)
      return; // no heading or no table of contents

    let i = 0;

    const removeNumberingFromHeading = (heading) => heading.replace(/^\d+\.\s*/, ''),
      halfWindowHeight = window.innerHeight / 2;

    $titles.each(function () {
      const $this = $(this),
        content = $this.text().trim(),
        contentWithoutNumbers = removeNumberingFromHeading(content);

      if (content.length) {
        const subItemClass = $this.is('h3') ? ' table-of-contents-list__item_subitem' : '',
          activeClass = i === 0 ? ' table-of-contents-list__item_active' : '';

        $items.append(`<li class="table-of-contents-list__item${activeClass}${subItemClass}" data-index="${i}" data-original-content="${content}">${contentWithoutNumbers}</li>`);
        i++;
      }
    });

    const $tableOfContentsItem = $('.table-of-contents-list__item');

    $tableOfContentsItem.click(function () {
      const $this = $(this),
        originalContent = $this.attr('data-original-content');

      if ($this.hasClass('table-of-contents-list__item_active'))
        return;

      $titles.each(function () {
        const $this = $(this),
          content = $this.text().trim();

        if (originalContent === content) {
          const offTop = $this.offset().top;
          $([document.documentElement, document.body]).scrollTop(offTop);
        }
      });
    });

    const setActiveTableOfContentElement = () => {
      const scrollTop = $(document).scrollTop();

      $tableOfContentsItem.removeClass('table-of-contents-list__item_active');

      for (let i = $titles.length - 1; i >= 0; i--) {
        const $heading = $($titles[i]),
          headingTopOffset = $heading.offset().top - halfWindowHeight + 150;

        if (scrollTop > headingTopOffset) {
          const content = $heading.text().trim();

          $(`.table-of-contents-list__item[data-original-content="${content}"]`).addClass('table-of-contents-list__item_active');
          break;
        }
      }
    }

    setActiveTableOfContentElement();
    $(window).scroll(() => {
      setActiveTableOfContentElement();
    });
  });

  /*============================================
  Open pdf file in new browser tab after submitting the form
  ==============================================*/
  $(function () {
    const $modal = $('.js-modal');

    if ($modal.length) {
      $modal.on('shown.bs.modal', function () {
        const $this = $(this),
          $pdfBtn = $this.find('.modal-content__btn'),
          $pdfUrl = $pdfBtn.attr("data-pdf-url"),
          existPdfBtnAndLink = $pdfUrl.length && $pdfBtn.length;

        if (existPdfBtnAndLink) {
          $pdfBtn.on('click', function (e) {
            e.preventDefault();
            window.open($pdfUrl, '_blank');
          });
        }
      });
    }
  });

  /*============================================
  Integrations template search filtering
  ==============================================*/
  $(function () {
    const isIntegrationTemplate = $('body').hasClass('page-template-page-integrations');

    if (!isIntegrationTemplate)
      return; // not execute if is not integration template

    const $integrations = $('.integrations'),
      $categoriesSearchInput = $integrations.find('.js-integrations-search'),
      $categoriesItem = $integrations.find('.js-integrations-categories .integrations__link'),
      $notFoundCategoryItem = $integrations.find('.js-integrations-categories-not-found'),
      $notFoundToolItem = $integrations.find('.js-integration-tool-not-found'),
      $toolItems = $integrations.find('.js-integrations-tool'),
      FADE_TIME_MS = 200;

    const toggleNotFoundVisibility = () => {
      if ($toolItems.not(':hidden').length < 1) {
        $notFoundCategoryItem.fadeIn(FADE_TIME_MS);
        $notFoundToolItem.fadeIn(FADE_TIME_MS);
      } else {
        $notFoundCategoryItem.fadeOut(FADE_TIME_MS);
        $notFoundToolItem.fadeOut(FADE_TIME_MS);
      }
    }

    const categoryHasTool = (tool, category) => {
      let found = false;
      const $toolTitle = $integrations.find(`#${category} .js-integrations-tool .card-integration__title`);

      $toolTitle.each(function () {
        const $this = $(this),
          toolTitle = $this.text().toLowerCase().trim();

        if (toolTitle.includes(tool)) {
          found = true;
        }
      });

      return found;
    }

    $categoriesSearchInput.on('input', (e) => {
      const searchValue = e.target.value.toLowerCase();

      // Toggle visibility for tool items
      $toolItems.each(function () {
        const $tool = $(this),
          toolTitle = $tool.find('.card-integration__title').text().toLowerCase().trim();

        if (toolTitle.includes(searchValue)) {
          $tool.fadeIn(FADE_TIME_MS);
        } else {
          $tool.fadeOut(FADE_TIME_MS);
        }
      });

      // Toggle visibility for category items
      $categoriesItem.each(function () {
        const $category = $(this),
          categoryId = $category.attr('aria-controls');

        if (categoryHasTool(searchValue, categoryId)) {
          $category.fadeIn(FADE_TIME_MS);
        } else {
          $category.fadeOut(FADE_TIME_MS);
        }
      });

      toggleNotFoundVisibility();
      setTimeout(() => toggleNotFoundVisibility(), FADE_TIME_MS + 50);
    });
  });

  /*============================================
  Copy URL hint in knowledge center table of contents
  ==============================================*/
  $(function () {
    const $copyButton = $('.table-of-contents-share__copy'),
      $url = $(location).attr('href'),
      $copyHint = $(".table-of-contents-share__copy-hint"),
      $copyHintText = $copyHint.data('copy-text');

    let $temp = $("<input>");

    if ($copyButton.length) {
      $copyButton.on('click', function () {
        $("body").append($temp);
        $temp.val($url).select();
        document.execCommand("copy");
        $temp.remove();
        $copyHint.text($copyHintText).fadeIn().delay(600).fadeOut();
      });
    }
  });

  /*============================================
  Knowledge center tags toggle active class
  ==============================================*/
  // Fix Dropdown closing on checkboxes click
  $(function () {
    const $dropdownMenuWithCheckboxes = $('.knowledge-filter-dropdown_checkboxes .knowledge-filter-dropdown-menu'),
          $dropdownTagLink = $dropdownMenuWithCheckboxes.find('.knowledge-filter-dropdown-menu__item_checkbox');

    if ($dropdownTagLink.length) {
      $dropdownTagLink.on('click', function () {
        const $this = $(this);
        $this.toggleClass("knowledge-filter-dropdown-menu__item_active");
      });
    }
  });

  /*============================================
   Add country flags to HS form phone number
 ==============================================*/
  $(function () {
    const $form = $('.js-hs-form'),
      $formId = $form.data('form-id');

    window.addEventListener('message', event => {
      if (event.data.type === 'hsFormCallback' && (event.data.eventName === 'onFormReady' || event.data.eventName === 'onFormFailedValidation') && event.data.id === $formId) {
        const $phone = $form.find('input[type="tel"]');

        if ($phone.length) {
          $phone.intlTelInput({
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
            autoPlaceholder: "aggressive",
            preferredCountries: ['de', 'us', 'gb', 'it', 'fr'],
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
              const phoneNumber = $phone.val();

              if (!phoneNumber.includes(selectedCountryData.dialCode)) {
                const selectedDialCode = '+' + selectedCountryData.dialCode;

                $phone.val(selectedDialCode);
                return selectedDialCode;
              }
            }
          });
        }
      }
    });
  });
})(jQuery);